//MailchimpForm.js
import React, {useState} from 'react'
import styled from "styled-components";
import addToMailchimp from 'gatsby-plugin-mailchimp'

const FormContainer = styled.div`
  text-align: center;
  margin: 40px auto 0 auto;
  padding: 50px;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 8%);

  @media (max-width: 768px) {
    padding: 20px;
  }

`

const Agreement = styled.label`
    font-size: 18px;
  padding: 20px;
  border-radius: 6px;
  span {
    margin-left: 10px;
  }
`;

export const NewsletterWrapper = styled.form`
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }

`
export const DescriptionWrapper = styled.div`
    text-align: center;
    flex-grow: 0;    
    flex-shrink: 0;
    flex-basis: 100%;    
    max-width: 100%;
`

export const InputWrapper = styled.div`
    flex-direction: column;
    justify-content: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 66.66667%;
  @media (max-width: 768px) {
    max-width: 100%;
  }

`

export const Input = styled.input`
    padding-top: 15px!important;
    padding-bottom: 15px!important;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid hsla(0,0%,90.2%,.95);
    border-radius: 6px;
    :invalid {
        border: 1px solid red;
    }
`


export const ButtonWrapper = styled.div`
    flex-direction: column;
    justify-content: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 33.33333%;
  @media (max-width: 768px) {
    max-width: 100%;
  }

`;

export const Button = styled.button`
    box-sizing: border-box;
    border: 2px solid ${props =>
    props.background ? props.background : 'white'};
    color: white;
    text-transform: uppercase;
    position: relative;
    padding-top: 15px!important;
    padding-bottom: 15px!important;
    outline: none;
    overflow: hidden;
    width: 100%;
    transition: all .2s ease-in-out;
    text-align: center;
    border-radius: 6px;
    background: ${props =>
    props.background ? props.background : 'hsl(227deg 31% 35%)'};
`

export default ({ }) => {
    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [GDPR, setGDPR] = useState(false);
    const [textColor, setColor] = useState({color: "#333", border: "none"});

    const handleSubmit = () => {
        if(GDPR === false) {
            setColor({color: "#F00", border: "2px solid red"});
        }
        else {
            addToMailchimp(email).then((data) => {

                if (data.result === "error") {
                    //errorHandling(data)
                } else {
                    setSubmitted(true)
                }
            })
        }
    }

    return (
        <FormContainer>
            {submitted ? (
                <>
                    <img src={checkMarkData}/>
                    <h3>Tvůj email jsme přidali. Děkujeme!</h3>
                </>
                ) : (
                <div>
                    <h2>Přidej se do našeho mailing listu</h2>
                    <NewsletterWrapper>
                        <DescriptionWrapper>
                            <p>
                                Chceš se dozvědět víc ohledně IT bezpečnosti? Tak si na správném místě.
                            </p>
                        </DescriptionWrapper>
                        <InputWrapper>
                            <Input
                                type="email"
                                name="email"
                                id="mail"
                                label="email-input"
                                placeholder="Tvoje mailová adresa"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </InputWrapper>
                    <ButtonWrapper>
                        <Button
                            type="button"
                            aria-label="Subscribe"
                            onClick={() => handleSubmit()}
                        >
                            Přihlásit se k odběru
                        </Button>
                    </ButtonWrapper>
                    <br/>
                    <Agreement style={textColor}>
                        <input type="checkbox" name={"checkBox"}
                        onClick={() => {
                            setGDPR(!GDPR);
                            setColor({color: "#333", border: "none"});
                        }}/>
                        <span>Souhlasím se zpracováním údajů dle GDPR</span>
                    </Agreement>
                    </NewsletterWrapper>
                </div>
            )}
        </FormContainer>
    )
}

const checkMarkData = "data:image/png;base64" +
    ",iVBORw0KGgoAAAANSUhEUgAAAEUAAABQCAYAAABPlrgBAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAABN1JREFUeJztm01oXFUYhp/3TBotrdVSF2JXtplRRKw1sQkKFkEX" +
    "LhQUFOyPrUg7TUC6E8Ef0gbEFisEsU2otBrahdWF4MaKpYooYjNaMGDTiQkEpVVJ/CFom5/5XKS3xjbpzNyZufdMOs8q995zzn3zcCf3nO9koEaNGjVqVBTla9C0t38FTo8aNGOsBK4XSkSQrTyIc" +
    "bBfgD6w46bFH2fSy/++cpc5aOzub5a5DuChcueMFbM/Dd5KjLPrm+2pv2ZrcpmU24/01S8cuWaXsO1IeZ+kasXgZ8TGTDp5/NJr//ul13SeXpKr50OkB6KLFx9mNiVja29b6sDM8xelrD04dO3Yuc" +
    "mjEvdHHy9eZLbhRGvqcHDsgh/Gzk10Xo1CAAwdaNp7enVw7ACauvsflLQ1vlgxI+rN6eATRywB4DATptfizhU3glVDo9mnANzd+7LNoMa4Q/mBngNwkh6PO4pHrGnZ17/cgd0XdxKfmHDuXieUjDu" +
    "IT8hIOhNL4w7iFWbLnKAu7hw+YVLC5W929TGPnhL7DNRvRrPEXaWMNA+kWM5Mz2RaUz3Th6amroGdiJfCjljlHx/LAZsyrcmei6ck621NvmzGjrCjVrGUaSG921KHZruaaU22hxVTpVKuLCQgrJgq" +
    "lFKYkIBMa7LdYGcxd6gyKcUJCcikG9rBevK1C6giKeGEACCZKbG/0OZVIqUEIcEIU5MFF+GrQErpQjCTnEsX2txzKeUR0tSd7RCsL7SLx1LKJwT0YjHdSpJi0Dk1Mbm8vm5yiYkthv1TyngzRo5NC" +
    "JSw9jHZK5l0qmPGqbfv6RoYypH7SGhh2HHjFgIhn5RZhABwYlvDMYd7JPwTE78QCCFlLiEB4cX4IQSKlJJPSEDxYvwRAsVIMToKERJQuBi/hEChUsyGb1nWUPRqM78Y/4RAgVIMsu8/qakwN5hbjJ" +
    "9CoEApQqta9gyHfs1eLsZfIVDox0fcOLHo/P5gVz4MgRhgDI+FQBF/aAXrB0ezB0sVM7nAVvgsBIp8JQttLFXMyWdTv4XtG4UQCDF5K4eYUEQkBEJO8yMXE6EQKGGVLLRxcGTgnYqLiVgIlFg6kNh" +
    "QUTExCIEyFJkqJiYmIVCmylvZxcQoBMpYjpTYMDg68G7JYmIWAmWu0U5P8EoQ44EQqEDhOrQYT4RAhar5RYvxSAhUcItDsH5oZKAnrxjPhECl933EuiuK8VAIRLEZNpcYT4VAVDuEYt3gaPbQnT0n" +
    "FwE0dvcuaOzO7vZRCICaurIW3e3sdzN9jywldFN09y0cg86I/ztSS6e/aOX3VxM93mCPD2dmoar08xebcIjw5cF5iLBfneBU3EH8QqecmT6PO4Y/WK4uMf6lk+mDuKP4g459veWOUdfb1tBnxqdxx" +
    "/EBwRtw4ZUss+ev+reQ8cmJdMNRuCClty31HbL2WEPFiMGIObcFyWDG5C1zJvWqweG5u85PzOyc5XKPZdIrh4Nz/81odyi3+MxPmw0OzNp7PmL2B+jhb9tu/WLm6csXIWZq7Dq9VXKvA4ujyhc1Zv" +
    "YVjqcz6dSPl16bc2W2+s0fbk7UJV4wtFniuspGjA4zTkJud+Zs6j12KDdbm7zL1ZY9wwunFp1fmzNakK3AdIOqaCFpcB5xFlkf5I5n0rfVZvA1atSoUcND/gXnnJMS4J0mmAAAAABJRU5ErkJggg==";
